import { useDispatchContext, useStateContext } from '../store';
import * as Comlink from 'comlink';
import { useState, useEffect } from 'react';
import { ErrorCallback, ProgressCallback, ResponseCallback, UploadFileWorker } from '../workers/uploadFileWorker';
import {gql, useApolloClient} from '@apollo/client';
import CryptoJS from 'crypto-js';


export type FileUploadWorkerProperties = {
  files: FileUploadProperties[],
  uploadType: 'task' | 'workspace' | 'process_step',
  selectedTask?: any,
  updateTaskStatus?: any,
  updateTask?: any,
  afterUpload?: any,
  processStep?: any
}

export type FileUploadProperties = {
  file: File;
  taskId?: string;
  fileVersionId?: string;
  workspaceId?: string;
  sourceType?: 'primary' | 'secondary';
  url?: string;
  fields?: Object;
  signedBlobId?: string;
  processStepId?: string;
}

type CreateSignedURLProperties = {
  filename: string;
  checksum: string;
  byteSize: number;
  contentType: string;
  taskId: string;
}

type FileProcessingProperties = {
  filename: string;
  signedBlobId: string;
  fileVersionId?: string;
  labelName?: string;
  taskId?: string;
  processStepId?: string;
  workspaceId?: string;
  sourceType?: string;
}


export const useFileUploadWorker = () => {
  const dispatch = useDispatchContext();
  const state = useStateContext();

  const [fileUploadWorkerProperties, setFileUploadWorkerProperties] = useState<FileUploadWorkerProperties>();
  const [filesWithUploadParams, setFilesWithUploadParams] = useState<FileUploadProperties[]>([]);

  const apolloClient = useApolloClient();

  //This useEffect will trigger when files are added
  useEffect(() => {
    if (fileUploadWorkerProperties && fileUploadWorkerProperties.files && fileUploadWorkerProperties.files.length) {

      //Format the files for the display popper
      const popperFiles = fileUploadWorkerProperties.files.reduce((fileObject, file) => {
        const tempObject: any = new Object();
        tempObject[`${file.file.name}`] = {
          total: 100,
          loaded: 0
        };

        return Object.assign(fileObject, tempObject);
      }, {});

      // Reset the files uploaded state
      dispatch({
        type: 'SET_EXCEL_BATCH_FILES_UPLOADED',
        excelBatchFilesUploaded: null
      });

      dispatch({
        type: 'SET_FILE_UPLOAD_STATUS_STATE',
        fileUploadStatusState: {
          isOpen: true,
          files: popperFiles,
          selectedTask: fileUploadWorkerProperties.selectedTask || {},
          updateTask: fileUploadWorkerProperties.updateTask || null,
          updateTaskStatus: fileUploadWorkerProperties.updateTaskStatus || null,
          uploadType: fileUploadWorkerProperties.uploadType,
          processStep: fileUploadWorkerProperties.processStep || null
        },
      });

      const runMutation = async () => {

        // Calculate the MD5 checksum for each file
        const calculateFileChecksum = async (file: any) => {
          const chunkSize = 64 * 1024; // 64 KB chunks
          const fileReader = new FileReader();
          const hashBuffer: any[] = [];
        
          return new Promise((resolve, reject) => {
            fileReader.onload = function (e) {
              const chunkArray = new Uint8Array(e?.target?.result);
              hashBuffer.push(chunkArray);
        
              if (file.size > fileReader?.result?.length) {
                fileReader.readAsArrayBuffer(file.slice(fileReader.result.length, fileReader.result.length + chunkSize));
              } else {
                const concatenatedArray = new Uint8Array(hashBuffer.reduce((acc, curr) => acc.concat(Array.from(curr)), []));
                const checksum = CryptoJS.MD5(concatenatedArray).toString();
                resolve(checksum);
              }
            };
        
            fileReader.onerror = function (error) {
              reject(error);
            };
        
            // Start reading the first chunk
            fileReader.readAsArrayBuffer(file.slice(0, chunkSize));
          });
        }

        const fileProperties: CreateSignedURLProperties[] = await Promise.all(fileUploadWorkerProperties.files.map(async (file) => {
          // Grab properties for createSignedUrl Mutation
          let checksum:any = '';
          calculateFileChecksum(file.file)
            .then((chsum) => {
              checksum = chsum;
            })
          const filename = file.file.name;
          const contentType = file.file.type;
          const byteSize = file.file.size
          const taskId = file.taskId as string;

          return {
            filename,
            checksum,
            contentType,
            byteSize,
            taskId
          }
        }))

        //Generate Mutation String
        const createSignedURLMutation = generateCreateSignedURLMutation(fileProperties);

        const mutationData = await apolloClient.mutate({ mutation: createSignedURLMutation });

        //Format the files for upload
        const formattedFiles: FileUploadProperties[] = fileUploadWorkerProperties.files.map((file, index) => {
          //Copy the file
          const formattedFile = Object.assign({}, file);

          //Update the fields and url parameters
          formattedFile.fields = mutationData.data[`fileIndex${index}`].fields;
          formattedFile.url = mutationData.data[`fileIndex${index}`].url;
          formattedFile.signedBlobId = mutationData.data[`fileIndex${index}`].signedBlobId

          return formattedFile;
        });

        setFilesWithUploadParams(formattedFiles);
      }

      runMutation();
    }
  }, [fileUploadWorkerProperties]) //Add files back to this

  //This useEffect will trigger when we get all of the signedUrls back from the mutation
  useEffect(() => {
    //Create worker per file
    if (filesWithUploadParams?.length) {
      filesWithUploadParams.forEach(async (file, index) => {

        //Create new Worker USING A BLOB((maybe?))
        const uploadFileWorker = new Worker(new URL('../workers/uploadFileWorker', import.meta.url), { type: 'module' });

        const uploadFileWorkerWrapper: UploadFileWorker = Comlink.wrap(uploadFileWorker);
        //Update Global popper

        const transferableFile = await file.file.arrayBuffer(); //Maybe rename so we don't have file.file

        //Progress callback to update popper state as file is uploaded
        const progressCallback: ProgressCallback = async (loaded: number, total: number) => {

          const files = state.fileUploadStatusState.files;
          files[file.file.name] = { loaded, total };

          dispatch({
            type: 'SET_FILE_UPLOAD_STATUS_STATE',
            fileUploadStatusState: {
              isOpen: true,
              files: files,
              selectedTask: fileUploadWorkerProperties!.selectedTask || {},
              updateTask: fileUploadWorkerProperties!.updateTask || null,
              updateTaskStatus: fileUploadWorkerProperties!.updateTaskStatus || null,
              uploadType: fileUploadWorkerProperties!.uploadType,
              processStep: fileUploadWorkerProperties!.processStep || null
            },
          });
        }

        //Respone callback to call the process file mutation after file upload
        const responseCallback: ResponseCallback = async (responseData) => {

          // Code for getting response elements if we need them in the future

          // const parser = new DOMParser();
          // const responseDocument = parser.parseFromString(responseData, "text/xml");
          // Extra values being returned in the xml response
          // const url = responseDocument.getElementsByTagName("Location")[0].childNodes[0].nodeValue;
          // const bucket = responseDocument.getElementsByTagName("Bucket")[0].childNodes[0].nodeValue;
          // const key = responseDocument.getElementsByTagName("Key")[0].childNodes[0].nodeValue;
          // const eTag = responseDocument.getElementsByTagName("ETag")[0].childNodes[0].nodeValue;

          //This type might be unneccessary
          const fileProcessingProperties: FileProcessingProperties = {
            filename: file.file.name,
            signedBlobId: file.signedBlobId!,
            taskId: file.taskId,
            workspaceId: file.workspaceId,
            fileVersionId: file.fileVersionId,
            sourceType: file.sourceType,
            processStepId: file.processStepId,
          }

          const processFileMutation = generateProcessFileMutation(fileProcessingProperties, fileUploadWorkerProperties?.uploadType!);

          const mutationData = await apolloClient.mutate({ mutation: processFileMutation });

          if (fileUploadWorkerProperties?.uploadType === 'process_step') {
            const taskData = mutationData?.data?.processFile?.task;
            
            if (taskData) {
              const updatedTask = {
                ...fileUploadWorkerProperties?.selectedTask,
                ...taskData,
              }

              const newFileUploadStatus = Object.assign({},{
                isOpen: true,
                files: state.fileUploadStatusState.files,
                selectedTask: updatedTask,
                uploadType: fileUploadWorkerProperties!.uploadType,
                processStep: fileUploadWorkerProperties!.processStep || null
              })

              dispatch({
                type: 'SET_FILE_UPLOAD_STATUS_STATE',
                fileUploadStatusState: newFileUploadStatus
              });

              fileUploadWorkerProperties?.updateTask(updatedTask)

              if(fileUploadWorkerProperties?.afterUpload) {
                fileUploadWorkerProperties.afterUpload(updatedTask, newFileUploadStatus);
              }
            }
          } else {
            const taskData = mutationData?.data?.processFile?.task;

            if ((fileUploadWorkerProperties?.updateTask || fileUploadWorkerProperties?.afterUpload) && taskData) {
              const updatedTask = {
                ...fileUploadWorkerProperties?.selectedTask,
                ...taskData,
                hasFiles: true,
                hasUnseenFiles: false
              }

              if (file.sourceType) {
                //This needs cleaned up, remove the file.file stuff
                const splitFileName = file.file.name.split('.');
                const possibleExcelTypes = ['xls', 'xlsx', 'xlsm', 'xlsb']
                const fileType = splitFileName[splitFileName.length - 1];

                //Check if the file needs processed or not
                if(possibleExcelTypes.includes(fileType)){
                  const newFileVersionId = taskData.files.find((taskFile: any) => taskFile.fileName === file.file.name && taskFile.updatedAt == "just now")?.id
                  const tempFileRecon = {
                    id: '-1',
                    value: 0,
                    fileVersion: {
                      fileName: file.file.name,
                      id: newFileVersionId
                    },
                    sourceType: file.sourceType
                  }

                  if(updatedTask[`${file.sourceType}FileReconSources`]?.length){
                    updatedTask[`${file.sourceType}FileReconSources`].push(tempFileRecon)
                  } else {
                    updatedTask[`${file.sourceType}FileReconSources`] = [tempFileRecon]
                  }
                }
              }
              const newFileUploadStatus = Object.assign({},{
                isOpen: true,
                files: state.fileUploadStatusState.files,
                selectedTask: updatedTask,
                updateTask: fileUploadWorkerProperties!.updateTask || null,
                updateTaskStatus: fileUploadWorkerProperties!.updateTaskStatus || null,
                uploadType: fileUploadWorkerProperties!.uploadType,
                processStep: null
              })

              dispatch({
                type: 'SET_FILE_UPLOAD_STATUS_STATE',
                fileUploadStatusState: newFileUploadStatus,
              });

              if(fileUploadWorkerProperties?.afterUpload){
                fileUploadWorkerProperties.afterUpload(updatedTask, newFileUploadStatus);
              } else {
                fileUploadWorkerProperties?.updateTask(updatedTask)
              }

              const primaryExcelBatch = updatedTask?.primaryFileReconSources?.filter((source: any) => source.sourceType === "primary" && source.id === "-1");
              const secondaryExcelBatch = updatedTask?.secondaryFileReconSources?.filter((source: any) => source.sourceType === "secondary" && source.id === "-1");
        
              const isPrimaryExcelBatchProcessing = !!primaryExcelBatch.length;
              const isSecondaryExcelBatchProcessing = !!secondaryExcelBatch.length;

              let newFileVersionId = null;
              if (isPrimaryExcelBatchProcessing) {
                newFileVersionId = primaryExcelBatch[0].fileVersion.id;
              } else if (isSecondaryExcelBatchProcessing) {
                newFileVersionId = secondaryExcelBatch[0].fileVersion.id;
              }

              // Update the files uploaded state
              dispatch({
                type: 'SET_EXCEL_BATCH_FILES_UPLOADED',
                excelBatchFilesUploaded: {
                  taskId: updatedTask.id,
                  fileVersionId: newFileVersionId,
                  isPrimaryExcelBatchProcessing,
                  isSecondaryExcelBatchProcessing,
                }
              });
            }
          }

          dispatch({
            type: 'SET_NOTIFICATION',
            notification: {
              variant: 'success',
              message: 'Uploaded files are currently being processed',
            },
          });

          uploadFileWorker.terminate();

        }

        //Error callback that still needs updated
        const errorCallback: ErrorCallback = async (error) => {
          //Handle Errors

          //Kill the file worker
          uploadFileWorker.terminate();
        }


        await uploadFileWorkerWrapper.uploadFile(Comlink.transfer(file, [transferableFile]), Comlink.proxy(progressCallback), Comlink.proxy(responseCallback), Comlink.proxy(errorCallback))
      })
    }
  }, [filesWithUploadParams])

  return (fileUploadWorkerProperties: FileUploadWorkerProperties) => {
    setFileUploadWorkerProperties(fileUploadWorkerProperties)
  }
}


const generateCreateSignedURLMutation = (fileProperties: CreateSignedURLProperties[]) => {

  //Create the individual mutation calls
  const mutationLiterals = fileProperties.map((properties, index) => {
    const tempString = `fileIndex${index}: createSignedUrl(
      filename: "${properties.filename}"
      byteSize: ${properties.byteSize}
      contentType: "${properties.contentType}"
      checksum: "${properties.checksum}"
      taskId: "${properties.taskId}"
      
      ){
        url
        fields
        signedBlobId
    }`

    return tempString
  })

  //Wrap the mutation calls
  const mutationString = `
    mutation {
      ${mutationLiterals}
    }
  `

  //Return created mutation
  return gql(mutationString)
}

const generateProcessFileMutation = (fileProcessingProperties: FileProcessingProperties, uploadType: 'task' | 'workspace' | 'process_step') => {

  if (uploadType === 'task') {
    const mutationLiteral = `
      processFile(
        filename: "${fileProcessingProperties.filename}"
        taskId: "${fileProcessingProperties.taskId}"
        signedBlobId: "${fileProcessingProperties.signedBlobId}"
        ${fileProcessingProperties.fileVersionId ? `fileVersionId: "${fileProcessingProperties.fileVersionId}"` : ''}
        ${fileProcessingProperties.sourceType ? `sourceType: "${fileProcessingProperties.sourceType}"` : ''}
        ${fileProcessingProperties.labelName ? `labelName: "${fileProcessingProperties.labelName}"` : ''}
      ) {
        task {
          id
          currency
          primaryFileReconSources {
            id
            sourceType
            fileVersion {
              id
              fileName
              fileLocation
              alterable
            }
            name
            isCurrent
            description
            prettyDate
            value
          }
          primaryFileReconSourcesTotal
          secondaryFileReconSources {
            id
            sourceType
            fileVersion {
              id
              fileName
              fileLocation
              alterable
            }
            name
            isCurrent
            description
            prettyDate
            value
          }
          secondaryFileReconSourcesTotal
          reconcilingItems {
            id
            sourceType
            fileVersion {
              id
              fileName
              fileLocation
              alterable
            }
            name
            isCurrent
            description
            prettyDate
            value
          }
          isReconciled
          primaryTotal
          secondaryTotal
          reconcilingItemTotal
          reconDifference
          files {
            id
            fileName
            fileLocation
            alterable
            viewed
            updatedAt
            taskId
            createdAt
            isCheckedOut
            checkedOutBy{
              fullName
            }
            checkedOutAt
            companyId
          }
        }
        success
      }
    `

    const mutationString = `
      mutation {
        ${mutationLiteral}
      }
    `
    return gql(mutationString)
  } else if (uploadType === 'process_step' && fileProcessingProperties.processStepId) {
    const mutationLiteral = `
      processFile(
        filename: "${fileProcessingProperties.filename}"
        processStepId: "${fileProcessingProperties.processStepId}"
        taskId: "${fileProcessingProperties.taskId}"
        signedBlobId: "${fileProcessingProperties.signedBlobId}"
        ${fileProcessingProperties.fileVersionId ? `fileVersionId: "${fileProcessingProperties.fileVersionId}"` : ''}
      ) {
        task {
          id
          name
          processSteps {
            id
            taskId
            description
            position
            completed
            files {
              id
              fileName
              fileLocation
              viewed
              alterable
              updatedAt
            }
          }
        }
        success
      }
    `

    const mutationString = `
      mutation {
        ${mutationLiteral}
      }
    `
    return gql(mutationString)
  } else {

    //Change this to hit the other endpoint
    const mutationLiteral = `
      processWorkspaceFile(
        filename: "${fileProcessingProperties.filename}"
        workspaceId: "${fileProcessingProperties.workspaceId}"
        signedBlobId: "${fileProcessingProperties.signedBlobId}"
        ${fileProcessingProperties.fileVersionId ? `fileVersionId: "${fileProcessingProperties.fileVersionId}"` : ''}
        ${fileProcessingProperties.labelName ? `labelName: "${fileProcessingProperties.labelName}"` : ''}
      ) {
        task {
          id
          primaryFileReconSources {
            id
            sourceType
            fileVersion {
              id
              fileName
              fileLocation
              alterable
            }
            name
            isCurrent
            description
            prettyDate
            value
          }
          primaryFileReconSourcesTotal
          secondaryFileReconSources {
            id
            sourceType
            fileVersion {
              id
              fileName
              fileLocation
              alterable
            }
            name
            isCurrent
            description
            prettyDate
            value
          }
          secondaryFileReconSourcesTotal
          reconcilingItems {
            id
            sourceType
            fileVersion {
              id
              fileName
              fileLocation
              alterable
            }
            name
            isCurrent
            description
            prettyDate
            value
          }
          isReconciled
          primaryTotal
          secondaryTotal
          reconcilingItemTotal
          reconDifference
          files {
            id
            fileName
            fileLocation
            alterable
            viewed
            updatedAt
            taskId
            createdAt
            isCheckedOut
            checkedOutBy{
              fullName
            }
            checkedOutAt
            companyId
          }
        }
        success
      }
    `

    const mutationString = `
      mutation {
        ${mutationLiteral}
      }
    `
    return gql(mutationString)
  }
}
