import {createMutationHook, gql} from '../graphqlHelpers';
import {SignInUser, SignInUserVariables} from './__generated__/SignInUser';

export const useSignInUser = createMutationHook<
  SignInUser,
  SignInUserVariables
>(gql`
  mutation SignInUser(
    $email: String!
    $password: String!
    $socialLogin: Boolean
    $provider: String
    $tokenID: String
    $uuID: String
    $companyName: String
    $timeStamp: String
    $workspaceId: String
    $fullName: String
    $profileUrl: String
    $rememberMe: Boolean
  ) {
    signInUser(
      email: $email
      password: $password
      socialLogin: $socialLogin
      provider: $provider
      tokenID: $tokenID
      uuID: $uuID
      companyName: $companyName
      timeStamp: $timeStamp
      workspaceId: $workspaceId
      fullName: $fullName
      profileUrl: $profileUrl
      rememberMe: $rememberMe
    ) {
      currentUser {
        id
        user {
          id
          fullName
          email
        }
      }
      errors {
        path
        message
      }
      success
    }
  }
`);
