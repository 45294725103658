import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import idx from 'idx';
import { useLazyQuery } from '@apollo/client'
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import {
  ClickAwayListener,
  Typography,
  Button,
  Paper,
  List,
  ListItem,
  ListItemText,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PersonIcon from '@material-ui/icons/Person';

import * as cs from '../../constants/theme';
import { useStateContext } from '../../store';
import DefaultUserImage from './DefaultUserImage';
import UserImage from './UserImage';
import { SEARCH_COMPANY_USERS } from '../../helpers/queries';
import {
  SearchCompanyUsers_searchCompanyUsers_users,
  SearchCompanyUsers_searchCompanyUsers_teams,
} from '../../helpers/__generated__/SearchCompanyUsers';
import { sortBy } from 'lodash';
import { classNames } from '../../helpers/helpers';

const useStyles = makeStyles((theme: Theme) => 
createStyles({
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    secondary: {
      color: '#606060',
      marginTop: '24px',
      marginBottom: '12px',
    },
    addButton: (props: any) => ({
      color: cs.COLORS.primary,
      border: '1px solid #D8D8D8',
      borderRadius: '3px',
      fontSize: '15px',
      marginBottom: '0px',
      '&:hover': {
        border: props.border
      }
    }),
    addSmallButton: (props: any) => ({
      color: cs.COLORS.primary,
      background: cs.COLORS.background,
      border: '1px solid #D8D8D8',
      borderRadius: '3px',
      fontSize: '15px',
      marginBottom: '0px',
      zIndex: 1,
      height: '26px',
      minWidth: '16px',
      '& span': {
        marginTop: '1px',
      },
      '&:hover': {
        background: cs.COLORS.background,
        border: props.border
      }
    }),
    addPanel: {
      maxHeight: 290,
      overflow: 'auto',
      position: 'absolute',
      width: '280px',
      marginTop: '3px',
      padding: '12px 24px 18px',
      backgroundColor: '#FFFFFF',
      border: '1px solid #D8D8D8',
      borderRadius: '3px',
      zIndex: 9,
    },
    panelLeft: {
      marginLeft: '-26px',
    },
    panelBottomLeft: {
      marginLeft: '-46px',
    },
    panelBottomRight: {
      marginLeft: '-15px',
    },
    panelRight: {
      marginLeft: '229px',
    },
    smallPanelPos: {
      top: '24px',
    },
    input: {
      display: 'block',
      width: '100%',
      marginTop: '6px',
      color: '#606060',
      fontFamily: cs.FONT.family,
      fontWeight: cs.FONT.weight.regular,
      fontSize: cs.FONT.size.xs,
      textTransform: 'none',
      '& label': {
        color: '#606060',
        fontFamily: cs.FONT.family,
        fontWeight: cs.FONT.weight.regular,
        fontSize: cs.FONT.size.xs,
      },
      '&:selected': {
        color: '#3A84FF',
      },
      '& input::placeholder': {
        fontSize: cs.FONT.size.xs,
      },
      '& div': {
        width: '100%',
      },
    },
    addLink: {
      marginTop: '6px',
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    borderDiv: {
      position: 'absolute',
      width: '360px',
      top: '0',
      backgroundColor: 'transparent',
      height: 180,
      marginLeft: '-227px',
    },
    textFieldContainer: (props: any) => ({
      '& .MuiInputBase-root': {
        cursor: props.cursor
      },
    }),
    placeholderInput: (props:any) => ({
      fontWeight: 500,
      border: 'none',
      '&:hover': {
        cursor: props.cursor
      },
      '& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before': {
        border: 'none',
      },
    }),
  })
)

interface OwnerFormProps {
  owners: (
    | SearchCompanyUsers_searchCompanyUsers_users
    | SearchCompanyUsers_searchCompanyUsers_teams
  )[];
  setOwners: React.Dispatch<
    React.SetStateAction<
      (
        | SearchCompanyUsers_searchCompanyUsers_users
        | SearchCompanyUsers_searchCompanyUsers_teams
      )[]
    >
  >;
  tasks?: any;
  id?: any;
  setTasks?: any;
  size?: 'small';
  handleAdd?: any;
  adminPlus?: any;
  formType?: any;
  /* If we pass an external company id, we want to search for users within
   * that company, not the current users' company, as is default  */
  externalCompanyId?: string;
  whatTheHell?: boolean;
  panelClass?: string;
  owners2?: any;
  popoverPosition?: 'bottom-left' | 'bottom-right';
  'data-cy'?: string;
  getReferencePoint?: number;
  additionalNewOwners?: any[];
  setAdditionalNewOwners?: any;
  openAddPanel: boolean;
  setOpenAddPanel: (open: boolean) => void;
  searchText?: string;
  searchTextUpdated?: (v: string) => void;
  // PlaceholderComponent?: React.Component;
}

interface InviteMemberType {
  name: string;
  email: string;
}

export default function OwnerForm(props: OwnerFormProps) {
  const {
    owners,
    setOwners,
    size,
    adminPlus,
    formType,
    externalCompanyId,
    panelClass,
    owners2,
    popoverPosition,
    openAddPanel,
    setOpenAddPanel,
    searchText,
    searchTextUpdated
  } = props;

  const [openInvitePanel, setOpenInvitePanel] = useState<boolean>(false);
  const [searchUsername, setSearchUsername] = useState<string>(searchText || '');
  const state = useStateContext();
  const companyId = externalCompanyId || state.selectedCompany;


  const styleProps = {
    cursor: state.currentUser.isExternalUser ? 'not-allowed' : 'pointer',
    border: state.currentUser.isExternalUser ? '1px solid #CCCCCC' : '2px solid #3A84FF'
  }
  const classes = useStyles(styleProps);

  const [leftAlign, setLeftAlign] = useState(true);

  const [searchResult, setSearchResult] = useState<any>({
    __typename: 'SearchCompanyUsers',
    users: null,
    teams: null,
  });
  const [inviteMember, setInviteMember] = useState<InviteMemberType>({
    name: '',
    email: '',
  });

  const [searchCompanyUsers, { loading, data, error, }] = useLazyQuery(
    SEARCH_COMPANY_USERS,
    {
      fetchPolicy: 'no-cache'
    }
  );

  // useEffect(function returnAllCompanyOwnersOnFirstRender() {
  //   searchCompanyUsers({
  //     variables: {
  //       text: '',
  //       companyId: companyId,
  //     },
  //   });
  // }, [companyId]);

  useEffect(() => {
    const result = idx(data, data => data.searchCompanyUsers);

    if (loading || !result) return;

    // get unique owners from the two arrays (existing and newly added owners)
    const newOwners = searchNewOwners(props.additionalNewOwners ? props.additionalNewOwners : [], searchUsername);
    const uniqueOwners: any = [];
    function checkAndAdd(owner: any) {
      for (let i = 0; i < uniqueOwners.length; i++) {
        let existingOwner = uniqueOwners[i]
        if (owner.__typename === existingOwner.__typename && (
          (owner.email && (owner.email === existingOwner.email)) ||
          (owner.name && (owner.name === existingOwner.name))
        )) { return; }
      }
      uniqueOwners.push(owner);
    }
    result.users.forEach(checkAndAdd);
    newOwners.forEach(checkAndAdd);

    const sortedResult = {
      users: sortBy(uniqueOwners, ['fullName']),
      teams: sortBy(result.teams, ['name'])
    };
    setSearchResult(sortedResult);
  }, [loading, idx(data, data => data.searchCompanyUsers), props.additionalNewOwners ? props.additionalNewOwners : undefined]);

  useEffect(() => {
    const newOwners = props.additionalNewOwners ? props.additionalNewOwners : [];
    const sortedResult = {
      __typename: 'SearchCompanyUsers',
      users: sortBy(newOwners, ['fullName']),
      teams: null,
    };
    setSearchResult(sortedResult);
  }, [error]);

  useEffect(() => {
    if (searchTextUpdated) searchTextUpdated(searchUsername);
    const companyId = externalCompanyId || state.selectedCompany;
    searchCompanyUsers({
      variables: { text: searchUsername, companyId: companyId },
    });
  }, [searchUsername]);

  const searchNewOwners = (newOwners: any[], searchString: string) => {
    return newOwners.filter((owner: any) => {
      let matchingOwner = false;
      if(owner?.email?.toLowerCase()?.includes(searchString) ||
        owner?.fullName?.toLowerCase()?.includes(searchString)||
        owner?.displayName?.toLowerCase()?.includes(searchString)){
          matchingOwner = true;
        }

        return matchingOwner;
    })
  }

  const handleCloseAll = () => {
    setOpenAddPanel(false);
    setOpenInvitePanel(false);
    setSearchResult({
      __typename: 'SearchCompanyUsers',
      users: null,
      teams: null,
    });
    setSearchUsername('');
  };

  const toggleAddPanel = () => {
    if (state.currentUser.isExternalUser) { return; }
    setOpenAddPanel(!openAddPanel);
  };

  const handleChangeSearchUsername = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setSearchUsername(value);
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) {
      const companyId = externalCompanyId || state.selectedCompany;
      searchCompanyUsers({
        variables: { text: searchUsername, companyId: companyId },
      });
    }
  };

  const handleClickUser = (event: React.MouseEvent<unknown>, index: number) => {
    if (searchResult.users && searchResult.users[index]) {
      setSearchResult({
        ...searchResult,
        users: [
          ...searchResult.users.slice(0, index),
          ...searchResult.users.slice(index + 1),
        ],
      });

      var newUser = { ...searchResult.users[index] };
      newUser.displayName = newUser.fullName || newUser.email;
      var newOwners = [...owners, newUser];
      setOwners(newOwners);
      if (props.handleAdd) {
        props.handleAdd(newUser);
      }
      setSearchUsername('');
    }
    setOpenAddPanel(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setInviteMember({
      ...inviteMember,
      [name]: value,
    });
  };

  const addNewOwner = (newOwner: any) => {
    var newOwners = props.additionalNewOwners ? props.additionalNewOwners : [];
    const temparr = [...owners, ...newOwners];
    for (let i = 0; i < temparr.length; i++) {
      let existingOwner = temparr[i]
      if (newOwner.__typename === existingOwner.__typename && (
        (newOwner.email && (newOwner.email === existingOwner.email)) ||
        (newOwner.name && (newOwner.name === existingOwner.name))
      )) { return; }
    }
    newOwners.push(newOwner);
    return newOwners;
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    /** we assign a random string as an id, beacuse assignee filter requires
     *  a string. */
    const newOwner = {
      __typename: 'User',
      id: Math.random().toString(),
      email: inviteMember.email,
      fullName: inviteMember.name,
      displayName: inviteMember.name || inviteMember.email,
      profileUrl: '',
    } as any;

    if (props.setAdditionalNewOwners) props.setAdditionalNewOwners(addNewOwner(newOwner))
    if (props.handleAdd) props.handleAdd(newOwner);
    setOwners([...owners, newOwner]);
    setInviteMember({
      name: '',
      email: '',
    });
    setSearchResult({ ...searchResult, users: [...searchResult.users, newOwner] })

    setOpenAddPanel(false);
  };

  const handleClickTeam = (event: React.MouseEvent<unknown>, index: number) => {
    if (searchResult.teams && searchResult.teams[index]) {
      setSearchResult({
        ...searchResult,
        teams: [
          ...searchResult.teams.slice(0, index),
          ...searchResult.teams.slice(index + 1),
        ],
      });
      var newTeam = { ...searchResult.teams[index] };
      var newOwners = [...owners, newTeam];
      setOwners(newOwners);
      if (props.handleAdd) {
        props.handleAdd(newTeam);
      }
      setSearchUsername('');
      setOpenAddPanel(false);
    }
  };

  const handleMouseOverMenu = (e: any) => {
    var rect = e.target.getBoundingClientRect();
    var room = window.innerWidth - rect.left;
    // this is deciding whether there is enough room to display a left-aligned
    // modal for adding users

    // in case of create-project we cannot use the window.innerwidth as the side panel is not rightmost.
    if (props.getReferencePoint) {
      room = props.getReferencePoint - rect.left;
    }
    if (room > 240) {
      setLeftAlign(false);
    } else {
      setLeftAlign(true);
    }
  };

  const handleClickAddMember = (event: any) => {
    event.stopPropagation();
    setOpenInvitePanel(true);
  }

  const Icon = formType === 'owners' ? PersonOutlineOutlinedIcon : PersonIcon;

  const isExternalUser = state.currentUser.isExternalUser;
  
  let textValue ;

  if(formType === 'reviewers'){
    textValue = "Add reviewer...";
  } else if (formType === "firstReviewers"){
    textValue = "Add first reviewer...";
  } else if (formType === "secondReviewers"){
    textValue = "Add second reviewer...";
  } else {
    textValue = "Add owner..."
  }

  return (
    <div
      data-cy="invite-form-container"
      style={{
        position: 'relative',
        backgroundColor: 'transparent',
        cursor: isExternalUser ? 'not-allowed' : 'pointer'
      }}
    >
      {owners2 && !owners2.length ? (
        <div className={classes.textFieldContainer} style={{ cursor: isExternalUser ? 'not-allowed' : 'pointer' }}>
          <TextField
            style={{ cursor: isExternalUser ? 'not-allowed' : 'pointer' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon
                    style={{
                      color: '#AFAFAF',
                      fontSize: '21px',
                      cursor: isExternalUser ? 'not-allowed' : 'pointer',
                    }}
                  />
                </InputAdornment>
              ),
            }}
            className={classes.placeholderInput}
            inputProps={{
              style: {
                color: '#AFAFAF',
                fontSize: '12px',
                fontFamily: 'Montserrat',
                fontWeight: 500,
                cursor: isExternalUser ? 'not-allowed' : 'pointer',
              },
              'data-cy': props['data-cy'],
            }}
            onClick={toggleAddPanel}
            onMouseOver={e => handleMouseOverMenu(e)}
            value={textValue}
          />
        </div>
      ) : (
        <div
          id={formType || 'plusButton'}
          style={{
            margin: props.whatTheHell
              ? '0px 20px 0px 0px0'
              : '4px 20px 0px 2px',
            verticalAlign: 'middle',
            cursor: isExternalUser ? 'not-allowed' : 'pointer'
          }}
        >
          {size && size === 'small' ? (
            <Button
              className={classes.addSmallButton}
              onClick={toggleAddPanel}
              onMouseOver={e => handleMouseOverMenu(e)}
              data-cy={props['data-cy']}
              style={{ cursor: isExternalUser ? 'not-allowed' : 'pointer' }}
            >
              +
            </Button>
          ) : (
            <Button
              className={classes.addButton}
              onClick={toggleAddPanel}
              //onMouseOver={e => handleMouseOverMenu(e)}
              style={{ marginBottom: 16, cursor: isExternalUser ? 'not-allowed' : 'pointer' }}
              data-cy={props['data-cy']}
            >
              +
            </Button>
          )}
        </div>
      )}

      {openAddPanel ? (
        <div
          className={classes.borderDiv}
          data-cy="open-add-panel"
          style={{
            top: size == 'small' ? '0px' : '36px',
          }}
        >
          <ClickAwayListener
            onClickAway={() => {
              setOpenAddPanel(false);
            }}
          >
            <Paper
              className={clsx(
                classes.addPanel,
                popoverPosition
                  ? popoverPosition === 'bottom-left'
                    ? classes.panelBottomLeft
                    : classes.panelBottomRight
                  : leftAlign
                    ? classes.panelLeft
                    : classes.panelRight,
                size === 'small' && classes.smallPanelPos,
                panelClass
              )}
              elevation={0}
              style={{ marginTop: props.whatTheHell ? '0px' : '3px', maxHeight: 200 }}
            >
              <TextField
                className={classes.input}
                data-cy="invite-user"
                onChange={handleChangeSearchUsername}
                onKeyUp={handleKeyUp}
                placeholder="Search by name or email"
                value={searchUsername}
              />
              <List aria-labelledby="Invite Owner Panel" component="div">
                {searchResult &&
                  searchResult.users &&
                  searchResult.users.map(
                    (
                      user: SearchCompanyUsers_searchCompanyUsers_users,
                      index: number
                    ) => {
                      return (
                        <ListItem
                          data-cy="known-user"
                          disableGutters
                          key={user.id}
                          onClick={(event: React.MouseEvent<unknown>) =>
                            handleClickUser(event, index)
                          }
                          style={{ cursor: isExternalUser ? 'not-allowed' : 'pointer' }}
                        >
                          <UserImage user={user} />
                          <ListItemText
                            primary={user.fullName || user.email}
                            style={{ marginLeft: '12px', cursor: isExternalUser ? 'not-allowed' : 'pointer' }}
                          />
                        </ListItem>
                      );
                    }
                  )}
                {searchResult &&
                  searchResult.teams &&
                  searchResult.teams.map(
                    (
                      team: SearchCompanyUsers_searchCompanyUsers_teams,
                      index: number
                    ) => {
                      return (
                        <ListItem
                          data-cy="known-team"
                          disableGutters
                          key={team.id}
                          onClick={(event: React.MouseEvent<unknown>) =>
                            handleClickTeam(event, index)
                          }
                        >
                          <DefaultUserImage userName={team.name} />
                          <ListItemText
                            primary={team.name}
                            style={{ marginLeft: '12px' }}
                          />
                        </ListItem>
                      );
                    }
                  )}
              </List>
              {searchResult &&
                searchResult.users &&
                searchUsername &&
                !searchResult.users.length &&
                searchResult.teams &&
                !searchResult.teams.length && (
                  <Typography variant="h3">No match result</Typography>
                )}
              {adminPlus && openInvitePanel && (
                <form onSubmit={handleSubmit}>
                  <Typography style={{ marginTop: '24px' }} variant="h6">
                    New owner
                  </Typography>
                  <TextField
                    className={classes.input}
                    data-cy="invite-name"
                    label="Name"
                    name="name"
                    onChange={handleChange}
                    value={inviteMember.name}
                  />
                  <TextField
                    className={classes.input}
                    data-cy="invite-email"
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    required
                    type="email"
                    value={inviteMember.email}
                  />
                  <Button
                    className={classes.addLink}
                    data-cy="invite-button"
                    type="submit"
                  >
                    Invite new member
                  </Button>
                </form>
              )}
              <Button
                className={classNames(classes.addLink, (adminPlus && !openInvitePanel) ? 'block' : '!hidden')}
                data-cy="add-member-button"
                onClick={handleClickAddMember}
              >
                + Add Member
              </Button>
            </Paper>
          </ClickAwayListener>
        </div>
      ) : null}
    </div>
  );
}
