import idx from 'idx';
import moment from 'moment';
import {
  RDS_MESSAGE_BASE_URL,
  RDS_MESSAGE_KEY,
} from '../modules/common/Constants';
import { TaskViewTabs } from './navigation';
import { TaskStatus } from '../constants/types';

export function unique(array: any[]) {
  return [...new Set(array)];
}

// All of the caveats about using Stringify apply
export function uniqueObjects(array: any[]) {
  var stringArray = array.map((item: any) => JSON.stringify(item));
  var setUnique = unique(stringArray);
  return setUnique.map((str: string) => JSON.parse(str));
}

export function truncate(string: string, length: number) {
  if (string.length > length) {
    return string.substr(0, length) + '...';
  } else {
    return string;
  }
}

export const decryptMessage = async (message: any) => {
  if (!message) return;
  var a = await fetch(`${RDS_MESSAGE_BASE_URL}?key=` + message, {
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': `${RDS_MESSAGE_KEY}`,
    },
  });
  var b = await a.json();
  return b.message;
};

export const hashMessage = async (message: any) => {
  var hashResponse = await fetch(`${RDS_MESSAGE_BASE_URL}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': `${RDS_MESSAGE_KEY}`,
    },
    body: JSON.stringify({ message: message }),
  });

  return await hashResponse.json();
};

export function getModifiedTime(timeString: string) {
  var now = new Date().getTime();
  var then = new Date(timeString).getTime();
  if (Number.isNaN(then)) {
    return timeString;
  }

  var diff = now - then;
  if (diff < 60000) return 'just now';
  if (diff >= 60000 && diff < 3600000) {
    return Math.floor(diff / 60000) + ' minutes ago';
  }
  if (diff >= 3600000 && diff < 86400000) {
    return Math.floor(diff / 3600000) + ' hours ago';
  }
  if (diff >= 86400000) {
    return Math.floor(diff / 86400000) + ' days ago';
  }
  return '';
}

export function getCSRF() {
  return idx(
    document,
    (d: any) => d.querySelector('meta[name="csrf-token"]').content
  );
}

export function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export const apiPaths: any = {
  tasks: '/api/export_task',
	tasksFiles: '/api/export_task_files',
	rejectedTasksReport: '/api/export_rejected_tasks_report'
};

/**
 * Convert the value of selected tab from string to number
 * @param {string} str - the string value of selected tab
 * @returns {number} id - the index of selected tab
 *
 */
export function convertTaskPageViewTabStringToInt(str: string | number): number {
  if (typeof str === 'string') {
    const id = Object.keys(TaskViewTabs).indexOf(str);
    return id !== -1 ? id : 0;
  }
  return str;
}

/**
 * Convert the value of selected tab from number to string
 * @param {number} id - the index of selected tab
 * @returns {string} str - the string value of selected tab
 *
 */
export function convertTaskPageViewTabIntToString(id: number): string {
  if (typeof id === 'number') {
    let str = '';
    Object.keys(TaskViewTabs).forEach((value) => {
      if (id === Object.keys(TaskViewTabs).indexOf(value)) {
        str = value;
      }
    });
    return str;
  }
  return id;
}

/**
 * Update a list of notifications
 * @param {notifs} any[] - the notifications list
 * @param {oldMsgKey} string - the old message key to replace
 * @param {newMsgKey} string - the new message key to use
 * @param {mewMsgText} string - the new message text to use
 * @returns {any[]} any[] - the notifications list updated
 */
export function updateMessageNotification(
  notifs: any[],
  oldMsgKey: string,
  newMsgKey: string,
  mewMsgText: string
): any[] {
  const notifications = JSON.parse(JSON.stringify(notifs));
  for (let i = 0; i < notifications.length; i++) {
    if (notifications[i].description === oldMsgKey) {
      notifications[i].isEdited = true;
      notifications[i].description = newMsgKey;
      notifications[i].tempNewMessage = mewMsgText;
      notifications[i].prevMessageKey = oldMsgKey;
      break;
    }
  }
  return notifications;
}

/**
 * Create a local message notification for a task
 * @param {message} any - the message object
 * @param {task} any - the task object
 * @returns {any} any - the notification created
 */
export function createTaskNotificationFromMessage(message: any, task: any): any {
  return {
    actionType: message.isPublic ? 'New Public Messages' : 'New Internal Messages',
    createdAt: message.createdAt,
    description: message.message,
    user: message.user,
    isPublic: message.isPublic,
    name: '',
    messageId: message.id,
    isEdited: message.isEdited,
    url: `/app/newtasks?listId=${task.list.id}&taskId=${task.id}&pane=2&msgId=${message.id}`,
    notifiable: {listId: task.list.id},
    notifiableType: 'Task',
    notifiableId: task.id,
    pane: 2
  }
}

/**
 * Replace a query params value in url with a new one
 * @param {url} string - the query url
 * @param {search} string - the query params key to looking for. Ex: 'pane='
 * @param {value} string - the query params value to looking for. Ex: '0'
 * @returns {string} str - the new url after replacement
 */
export function replaceQueryParamsInUrl(url: string, search: string, value: string | number): string {
  const searchIndex = url.indexOf(search);
  if (searchIndex !== -1) {
    const nextQueryParams = url.substring(searchIndex).indexOf('&');
    return url.substring(0, searchIndex) + search + value + (nextQueryParams !== -1 ? url.substring(searchIndex + nextQueryParams) : '')
  }
  return url;
}

/**
 * Update message object in deep children
 * @param {msgs} any[] - messages list
 * @param {updatedMessage} any - the new message object
 * @returns {any[]} any[] - the updated messages list
 */
export function updateMessageObjectInDeepChild(msgs: any[], updatedMessage: any): any[] {
  for (let i = 0; i < msgs.length; i++) {
    if (msgs[i].id === updatedMessage.id) {
      msgs[i] = updatedMessage;
      break;
    }
    if (msgs[i].childMessages && msgs[i].childMessages.length) {
      msgs[i].childMessages = updateMessageObjectInDeepChild(msgs[i].childMessages, updatedMessage);
    }
  }
  return msgs;
}

/**
 * Retrieves a message from an array of messages based on its ID.
 *
 * This function recursively searches through the array of messages and its child messages
 * to find the message with the specified ID.
 *
 * @param {any[]} msgs - The array of messages to search through.
 * @param {string} messageId - The ID of the message to retrieve.
 * @returns {any} - The message object with the specified ID, or undefined if not found.
 */
export function getMessageInMessages(msgs: any[], messageId: string): any {
  let messageFound: any;
  for (let i = 0; i < msgs.length; i++) {
    if (msgs[i].id === messageId) {
      messageFound = msgs[i];
      break;
    }
    if (msgs[i].childMessages && msgs[i].childMessages.length) {
      messageFound = getMessageInMessages(msgs[i].childMessages, messageId);
      if (messageFound) {
        break;
      }
    }
  }
  return messageFound;
};

/**
 * Updates the resolve status of child messages recursively based on a hierarchy.
 *
 * This function modifies the resolve status of child messages in a hierarchical manner.
 * It considers the resolve status of the parent message and the current resolve status
 * of each child message to determine whether an update is needed.
 *
 * @param {any} updatedMessage - The parent message whose child messages need to be updated.
 * @param {string} messageId - The ID of the parent message.
 * @param {string} nextResolveStatus - The resolve status to be set for the child messages.
 * @returns {void}
 */
export function updateChildMessageStatus(childMessages:any[], nextResolveStatus: string, checkNextStatus: boolean = true): any[] {
  const message_hierarchical_position = nextResolveStatus === "closed" ? 3 : (nextResolveStatus === "resolved" ? 2 : 1);

  childMessages.forEach((message: any) => {
    if (checkNextStatus) {
      const child_hierarchical_position = message.resolveStatusDescription === "closed" ? 3 : (message.resolveStatusDescription === "resolved" ? 2 : 1);
      const should_reopen_child_after_closed = child_hierarchical_position === 3 && message_hierarchical_position ===  1;
      if (!should_reopen_child_after_closed && child_hierarchical_position >= message_hierarchical_position) {
        return;
      }
    }
    message.resolveDate = new Date().toISOString();
    message.resolveStatusDescription = nextResolveStatus;
    if (message.childMessages && message.childMessages.length) {
      updateMessageChilds(message, nextResolveStatus, checkNextStatus);
    }
  });
  return childMessages;
};

/**
 * Recursively updates the resolve status of child messages based on a hierarchy.
 *
 * This function modifies the resolve status of child messages in a hierarchical manner
 * starting from the given message. It considers the resolve status of the current message
 * and its child messages to determine whether an update is needed.
 *
 * @param {any} newMessage - The message whose child messages need to be updated.
 * @param {string} nextResolveStatus - The resolve status to be set for the child messages.
 * @returns {void}
 */
export function updateMessageChilds(newMessage: any, nextResolveStatus: string, checkNextStatus: boolean = true): void {
  const message_hierarchical_position = newMessage.resolveStatusDescription === "closed" ? 3 : (newMessage.resolveStatusDescription === "resolved" ? 2 : 1);

  newMessage.childMessages.forEach((message: any) => {
    if (checkNextStatus) {
      const child_hierarchical_position = message.resolveStatusDescription === "closed" ? 3 : (message.resolveStatusDescription === "resolved" ? 2 : 1);
      const should_reopen_child_after_closed = child_hierarchical_position === 3 && newMessage.resolveStatusDescription ===  1;
      if (!should_reopen_child_after_closed && child_hierarchical_position >= message_hierarchical_position) {
        return;
      }
    }
    message.resolveDate = new Date().toISOString();
    message.resolveStatusDescription = nextResolveStatus;

    updateMessageChilds(message, nextResolveStatus, checkNextStatus);
  });
}

/**
 * Filters out closed messages and their child messages from the given array of messages.
 *
 * This function recursively traverses the array of messages, excluding messages with a
 * resolve status of 'closed' and their descendants from the filtered result.
 *
 * @param {any[]} msgs - The array of messages to filter.
 * @returns {any[]} - The filtered array of messages with open or resolved status.
 */
export function filterOutClosedMessages(msgs: any[]): any[] {
  let msgFiltered:any[] = [];
  for (let i = 0; i < msgs.length; i++) {
    const msg = JSON.parse(JSON.stringify(msgs[i]));
    let resolveStatus = ['open', 'resolved'];

    const passed = resolveStatus.some((rs:any) => rs === msg.resolveStatusDescription);
    if (passed) {
      if (msg.childMessages && msg.childMessages.length) {
        msg.childMessages = filterOutClosedMessages(msg.childMessages);
      }
      msgFiltered.push(msg);
    } else {
      if (msg.childMessages && msg.childMessages.length) {
        const children: any[] = filterOutClosedMessages(msg.childMessages);
        if (children?.length) {
          msgFiltered = msgFiltered.concat(children);
        }
      }
    }
  }
  return msgFiltered;
}

/**
 * Get distinct array of objects by specific key name
 * @param {array} any[] - array list
 * @param {key} string - the key to use
 * @returns {any[]} any[] - the distinct array list
 */
export function uniqueArrayOfObjectsByKey(array: any[], key: string, strict = false): any[] {
  return [...new Map(array.map(item => [strict ? item[key]?.toLowerCase().trim() : item[key], item])).values()];
}

/**
 * Add opacity to a hex color
 * @param {color} string - the hex color
 * @param {opacity} number - the opacity between 0 to 1 with decimal
 * @returns {string} string - the result color with opacity
 */
export function addOpacityToHexColor(color: string, opacity: number): string {
  // coerce values so ti is between 0 and 1.
  var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

/**
 * Delete attribute from array of objects
 * @param {list} any[] - the array list
 * @param {key} string - the key to delete
 * @returns {any[]} any[] - the result
 */
export function removeKeyFromArrayOfObjects(list: any[], key: string): any[] {
  return list.map((obj:any) => {
    const { [key]: _, ...newObj } = obj;
    return newObj;
  })
}

/**
 * Calculate a text size in pixel
 * @param {str} string - the text content
 * @param {fontSize} number - the fontSize to apply
 * @returns {number} number - the result in pixel
 * https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript
 * https://blocks.roadtolarissa.com/tophtucker/62f93a4658387bb61e4510c37e2e97cf
 */
export function measureTextInPixel(str: string, fontSize: number): number {
  const widths = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0.2796875,0.2765625,0.3546875,0.5546875,0.5546875,0.8890625,0.665625,0.190625,0.3328125,0.3328125,0.3890625,0.5828125,0.2765625,0.3328125,0.2765625,0.3015625,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.2765625,0.2765625,0.584375,0.5828125,0.584375,0.5546875,1.0140625,0.665625,0.665625,0.721875,0.721875,0.665625,0.609375,0.7765625,0.721875,0.2765625,0.5,0.665625,0.5546875,0.8328125,0.721875,0.7765625,0.665625,0.7765625,0.721875,0.665625,0.609375,0.721875,0.665625,0.94375,0.665625,0.665625,0.609375,0.2765625,0.3546875,0.2765625,0.4765625,0.5546875,0.3328125,0.5546875,0.5546875,0.5,0.5546875,0.5546875,0.2765625,0.5546875,0.5546875,0.221875,0.240625,0.5,0.221875,0.8328125,0.5546875,0.5546875,0.5546875,0.5546875,0.3328125,0.5,0.2765625,0.5546875,0.5,0.721875,0.5,0.5,0.5,0.3546875,0.259375,0.353125,0.5890625]
  const avg = 0.5279276315789471
  return Array.from(str).reduce(
    (acc, cur) => acc + (widths[cur.charCodeAt(0)] ?? avg), 0
  ) * fontSize;
}

/**
 * Check if date is valid for specific format
 * @param {date} string - the date
 * @param {format} string - the date format
 * @returns {boolean} boolean - the result of checking
 */
export function isValidDate(date: string, format: string, strict: boolean = true): boolean {
  return moment(date, format, strict).isValid();
}

/**
 * Format a given date to initial timezone set to 00:00:00
 * @param {Date, string, null} - the date in date format
 * @returns {string} - the result after transformation Ex: "2021-01-01T11:42:50.000Z"
 */
export function formatDateToZeroTimezone(date: Date | string | null): any {
  if (date && moment(date).isValid()) {
    return new Date(moment(date).format("YYYY-MM-DD")).toISOString(); // return a string
  }
  return date;
}

/**
 * Convert the given string date to a Date format
 * @param {string} - the date should be exactly in string format MM/DD/YYYY
 * @returns {Date | null} - the date after conversion 
 */
export function convertDateInCurrentTimezone(date: string): Date | null {
  if (date && typeof date === 'string' && moment(date).isValid()) {
    const today = new Date();
    const dateParts = date.split("/");
    today.setFullYear(Number(dateParts[2]));
    today.setMonth(Number(dateParts[0]) - 1);
    today.setDate(Number(dateParts[1]));
    return today;
  }
  return null;
}

/**
 * Convert the given date to a MM/DD/YYYY string format
 * @param {string | Date} - the date in string or Date format. Ex: "2023-06-24"
 * @returns {string} - the date after conversion as MM/DD/YYYY. Ex: "06/24/2023"
 */
export function convertDateTimeZoneInLocalDate(date: string | Date): string {
  if (date && moment(date).isValid()) {
    if (typeof date === 'string') {
      // When the passed date is in universal format. Ex: "2023-06-24"
      // Returns the local format. Ex: "06/24/2023"
      const universalDate = date.split("-");
      if (universalDate.length === 3 && universalDate[2].length === 2) {
        return universalDate[1] + "/" + universalDate[2] + "/" + universalDate[0]
      }
    }
    const dd = new Date(date);
    return String(dd.getMonth() + 1).padStart(2, '0') + "/" +  String(dd.getDate()).padStart(2, '0') + "/" + dd.getFullYear();
  }
  return "";
}

/**
 * Convert the given date to a YYYY-MM-DD string format
 * @param {string | Date} - the date in string or Date format. Ex: "06/24/2023"
 * @returns {string} - the date after conversion as YYYY-MM-DD. Ex: 2023-06-24"
 */
export function convertDateTimeZoneInUniversalDate(date: string | Date): string {
  if (date && moment(date).isValid()) {
    if (typeof date === 'string') {
      // When the passed date is already in universal format. Ex: "2023-06-24"
      // Returns the same date. Ex: "2023-06-24"
      const universalDate = date.split("-");
      if (universalDate.length === 3 && universalDate[0].length === 4 && universalDate[2].length === 2) {
        return date
      }

      // When the passed date is already in universal format. Ex: "24-06-2023"
      // Returns the same date. Ex: "2023-06-24"
      const universalDate2 = date.split("-");
      if (universalDate2.length === 3 && universalDate2[0].length === 2 && universalDate2[2].length === 4) {
        return universalDate2[2] + "-" + universalDate2[1] + "-" + universalDate2[0];
      }

      // When the passed date is in local format. Ex: "06/24/2023"
      // Returns the date in universal format. Ex: "2023-06-24"
      const fromLocalDate = date.split("/");
      if (fromLocalDate.length === 3 && fromLocalDate[2].length === 4) {
        return fromLocalDate[2] + "-" + fromLocalDate[0] + "-" + fromLocalDate[1];
      }

      // When the passed date is in local format. Ex: "2023/06/24"
      // Returns the date in universal format. Ex: "2023-06-24"
      const fromLocalDate2 = date.split("/");
      if (fromLocalDate2.length === 3 && fromLocalDate2[0].length === 4) {
        return fromLocalDate2[0] + "-" + fromLocalDate2[1] + "-" + fromLocalDate2[2];
      }
    }

    const dd = new Date(date);
    return dd.getFullYear() + "-" + String(dd.getMonth() + 1).padStart(2, '0') + "-" +  String(dd.getDate()).padStart(2, '0');
  }
  return "";
}

/**
 * Convert the given timezone string date into current server timezone formatting
 * @param {string} - the date should be exactly in string. Ex: "2023-08-01T00:00:00-04:00"
 * @returns {string} - the date after conversion. Ex: "2023-08-01T20:00:00-04:00"
 */
export function convertDateInServerResponse(date: string): string {
  if (date && typeof date === 'string') {
    if(date.includes('T')) {
      return date.replace("00:00:00", "20:00:00")
    }
  }
  return "";
}

/**
 * Set local task schedule in cache for optimistic UI
 * @param {string} - the schedule in string format
 * @returns {any} - the schedule in object format
 */
export function buildTaskScheduleInCache(sValue: string): any {
  if (sValue) {
    const scheduleTempl = {
      id: "",
      calendarType: "1",
      ends: null,
      endsAfter: null,
      endsOn: null,
      repeatInterval: null,
      repeatNumber: null,
      repeatType: null,
      selectedMonthday: null,
      selectedMonth: null,
      selectedSecond: 0,
      selectedWeekday: null,
      selectedYearday: null
    }
    const [sType, choice] = sValue.split("_")
    switch (sType) {
      case "0":
        return null;
      case "1":
        if (sValue.includes('_')) {
          return {...scheduleTempl, repeatType: "1", repeatNumber: 1, repeatInterval: 5}
        }
        return {...scheduleTempl, repeatType: "1"}
      case "2":
        return {...scheduleTempl, repeatType: "2", selectedWeekday: Number(choice)}
      case "3":
        return {...scheduleTempl, repeatType: "3"}
      case "4":
        return {...scheduleTempl, repeatType: "4"}
      case "5":
        if (sValue.includes('_')) {
          const [month, day] = choice.split('.');
          return {...scheduleTempl, repeatType: "5", selectedMonthday: Number(day), selectedMonth: month}
        }
        return {...scheduleTempl, repeatType: "5"}
    }
  }
  return null;
}

/**
 * Validates an email address.
 * @param {string} email - The email address to validate.
 * @returns {boolean} - True if the email address is valid, false otherwise.
 */
export function isValidEmail(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

/**
 * Returns the ordinal representation of a date number as a string.
 * @param selected - The number to be converted to its ordinal representation. Must be provided as a string.
 * @returns The ordinal representation of the input number (e.g., '1st', '2nd', '3rd', '4th', '11th', ... , '31th').
 * If the input is null or not a recognized number, it returns '____'.
 */
export function renderNth(selected: string): string {
  switch (selected) {
    case null: {
      return '____';
    }
    case '1': return '1st';
    case '2': return '2nd';
    case '3': return '3rd';
    default: return selected + "th";
  }
};

/**
 * Returns the last word of an input string split by underscores in camel case.
 *
 * @param {string} inputString - The input string to process.
 * @returns {string} The last word of the input string in camel case.
 */
export function getLastCamelCaseWord(inputString: string) {
  const words = inputString.split('_');
  const lastWord = words[words.length - 1];
  
  if (lastWord) {
    // Convert the last word to camel case
    return lastWord.charAt(0).toUpperCase() + lastWord.slice(1);
  } else {
    return '';
  }
}

export function deepCopy(obj:any): any {
  if (typeof obj !== 'object' || obj === null) {
    return obj; // Base case: return primitive values or null unchanged
  }

  let copy:any;
  if (Array.isArray(obj)) {
    copy = [];
    for (let i = 0; i < obj.length; i++) {
      copy[i] = deepCopy(obj[i]); // Recursively copy each element
    }
  } else {
    copy = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        copy[key] = deepCopy(obj[key]); // Recursively copy each property
      }
    }
  }
  return copy;
}

/**
 * Groups an array of status objects by their listId and returns them in ascending order of listId.
 * 
 * @param {Array} items - The array of status objects to group. Each object should have a listId property.
 * @returns {Object} An object where each key is a listId and the value is an array of status objects for that listId, sorted by listId.
 */
export function groupStatusesByList(items: any[]): any {
  const groupedByListId = items.reduce((acc:any, obj:any) => {
    const listId = obj.listId;
    if (!acc[listId]) {
      acc[listId] = [];
    }
    acc[listId].push(obj);
    return acc;
  }, {});

  return Object.keys(groupedByListId).sort((a, b) => parseInt(a) - parseInt(b)).reduce((acc:any, key:string) => {
    acc[key] = groupedByListId[key];
    return acc;
  }, {});
}

/**
 * Generates an array of status objects based on selected lists and their corresponding statuses.
 * 
 * @param {string[]} selectedLists - The selected list IDs to process.
 * @param {Object} statusesByList - An object where keys are list IDs and values are arrays of status objects for those lists.
 * @param {string[]} allLists - An array of all list IDs.
 * @param {string[]} defaultStatusesKeys - An array of default status keys to use if a list has no custom statuses.
 * @returns {Object[]} An array of status objects with keys and list IDs, combining custom and default statuses.
 */
export function customStatusObjects(selectedLists: string[], statusesByList: any, allLists: string[], defaultStatusesKeys: string[]): any[] {
  const statusesObjects: any[] = [];

  if (selectedLists.length) {
    let lists = selectedLists;
    if (selectedLists[0] === '-1' || selectedLists[0] === '-2') {
      lists = allLists;
    }
    const missingList = lists.filter((listId: string) => !statusesByList[listId]);
    const allSortedList = lists.filter((listId: string) => statusesByList[listId]).concat(missingList);
    allSortedList.forEach((listId: string) => {
      const listHasCustomStatuses = !!statusesByList[listId];
      if (listHasCustomStatuses) {
        const keys = statusesByList[listId].map((item: any) => item.key);
        keys.forEach((statusKey: string) => {
          const existIndex = statusesObjects.findIndex((statusObject: any) => statusObject.key === statusKey);
          if (existIndex === -1) {
            statusesObjects.push({key: statusKey, listId});
          } else {
            statusesObjects[existIndex].listId = listId;
          }
        });
      } else {
        defaultStatusesKeys.forEach((statusKey: string) => {
          const exist = statusesObjects.some((statusObject: any) => statusObject.key === statusKey);
          if (!exist) {
            statusesObjects.push({key: statusKey, listId});
          }
        });
      }
    });
  }
  return statusesObjects;
}

/**
 * Get the list of completed status keys based on selected lists
 * 
 * @param {string[]} selectedLists - The selected list IDs to process.
 * @param {Object} statusesByList - An object where keys are list IDs and values are arrays of status objects for those lists.
 * @returns {string[]} An array of status objects with keys and list IDs, combining custom and default statuses.
 */
export const getCompleteStatusesKeys = (selectedLists: string[], statusesByList: any) => {
  const completedIds: string[] = [];
  selectedLists.forEach((listId: string) => {
    if (['-1', '-2'].includes(listId)) {
      const keys = Object.keys(statusesByList);
      keys.forEach((key: string) => {
        const items = statusesByList[key];
        const completedKey = items[items.length - 1].key;
        if (!completedIds.includes(completedKey)) {
          completedIds.push(completedKey);
        }
      });
    } else if (statusesByList[listId]) {
      const items = statusesByList[listId];
      const completedKey = items[items.length - 1].key;
      if (!completedIds.includes(completedKey)) {
        completedIds.push(completedKey);
      }
    } else {
      const completedKey = TaskStatus.Complete;
      if (!completedIds.includes(completedKey)) {
        completedIds.push(completedKey);
      }
    }
  });
  return completedIds;
}