import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { IProject } from '../common/interfaces';
import idx from 'idx';
import OwnerForm from '../../../../common/InviteForm';
import { SearchCompanyUsers_searchCompanyUsers_teams, SearchCompanyUsers_searchCompanyUsers_users } from '../../../../../helpers/__generated__/SearchCompanyUsers';
import RenderOwnersRow from './RenderOwnersRow';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			height: '100%',
			// width: 450,
			flexWrap: 'wrap',
		},
	})
);

interface IExternalUsers {
	newTemplate: IProject;
	setNewTemplate: React.Dispatch<React.SetStateAction<IProject>>;
	externalCompanyId: string;
	isAdmin: boolean;
	additionalExternalOwners: any[];
	setAdditionalExternalOwners: React.Dispatch<React.SetStateAction<any[]>>;
}

export default function ExternalUsers(props: IExternalUsers) {

	const classes = useStyles();
	const { newTemplate, setNewTemplate, externalCompanyId, isAdmin, additionalExternalOwners, setAdditionalExternalOwners } = props;

	const setOtherCompanyOwners = (owners: React.SetStateAction<(SearchCompanyUsers_searchCompanyUsers_users | SearchCompanyUsers_searchCompanyUsers_teams)[]>) => {
		setNewTemplate({
			...newTemplate,
			otherCompanyOwners: owners,
		});

	};

	const handleRemoveOtherCompanyOwnerClick = (removedOwner: any) => {
		const owners = newTemplate.otherCompanyOwners;
		if (owners && owners.length <= 1) return; // a list must have at least 1 owner

		// if removed Owner shares id && typename, take it out
		const newOwners = owners && owners.filter((owner: any) => {
			if (owner.__typename === removedOwner.__typename) {
				return owner.email !== removedOwner.email;
			} else {
				return true;
			}
		});
		newOwners && setOtherCompanyOwners(newOwners);
	};

	return (
		<div className={classes.root} data-cy='external-owners'>
			{(newTemplate.otherCompanyOwners && newTemplate.otherCompanyOwners.length > 0) &&
				<>
					<RenderOwnersRow style={{ height: 36 }} owners={newTemplate.otherCompanyOwners} handleRemoveOwnerClick={handleRemoveOtherCompanyOwnerClick} />
					<OwnerForm owners={newTemplate.otherCompanyOwners} setOwners={setOtherCompanyOwners} adminPlus={true} externalCompanyId={externalCompanyId} whatTheHell={true}
						additionalNewOwners = {additionalExternalOwners} setAdditionalNewOwners = {setAdditionalExternalOwners}/>
				</>
			}
		</div>
	)
}
