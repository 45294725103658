import React, { useState, useEffect } from 'react';
import idx from 'idx';
import clsx from 'clsx';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/styles';
import { Typography, FormControl, FormControlLabel, RadioGroup, Radio, Button, Checkbox } from '@material-ui/core';
import * as cs from '../../../../../../constants/theme';
import { RECON_TYPES } from '../../../../../../constants/types';
import { DatePicker } from '../../../../../common/DatePicker';
import OwnerForm from '../../../../../common/InviteForm';
import Dropdown from '../../../../../common/Dropdown';
import InternalIcon from '@material-ui/icons/Lock';
import UpdateIcon from '@material-ui/icons/Create';
import ShareIcon from '@material-ui/icons/People';
import RequestIcon from '@material-ui/icons/Input';
import WarningIcon from '@material-ui/icons/WarningRounded';
import { CustomRecurringProjects } from '../../../../shared/CustomRecurringProjects';
import { useStateContext } from '../../../../../../store';

import useRetentionPolicies from '../../../../../../graphql/queries/RetentionPolicies';
import { SearchCompanyUsers_searchCompanyUsers_teams, SearchCompanyUsers_searchCompanyUsers_users } from '../../../../../../helpers/__generated__/SearchCompanyUsers';
import { INewCompanyType, IProject, IProjectOptionsSelected } from '../../common/interfaces';
import RenderOwnersRow from '../../common/RenderOwnersRow';
import { RepeatingProjectDropdown } from '../../../../shared/RepeatingProjectDropdown';
import ExternalUsers from '../../common/ExternalUsers';
import ExternalCompany from '../../common/ExternalCompany';
import EditableInput from '../../common/EditableInput';
import { useGlobalStyles } from '../../../../../common/Style';
import ListLabelsContainer from '../../../../shared/ListLabelsContainer';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			height: '100%',
			width: '100%',
			overflow: 'auto',
		},
		warning: {
			display: 'flex',
			width: '100%',
			alignItems: 'center',
			backgroundColor: 'rgba(248,231,28,0.20)',
			border: '1px solid #D8D8D8',
			borderRadius: '3px',
			marginTop: '12px',
			padding: '12px 20px',
		},
		none: { visibility: 'hidden' },
		pencil: { marginRight: '10px' },
		body: {
			display: 'flex',
			height: '100%',
			width: '100%',

			padding: '0px calc((100% - 1080px) / 2) 0px calc((100% - 1080px) / 2)',
			// borderBottom: '1px solid #D8D8D8',
			// overflow: 'auto',
		},
		pane: {
			width: '500px',
			padding: '20px',
		},
		footer: {
			height: '60px',
			padding: '0px calc((100% - 1080px) / 2) 0px calc((100% - 1080px) / 2)',
		},
		flex: {
			display: 'flex',
			alignItems: 'center',
		},
		secondaryText: {
			color: '#606060',
			marginTop: 8,
			marginBottom: 6,
		},
		grow: {
			flexGrow: 1,
		},
		secondary: {
			color: '#606060',
			marginTop: '24px',
			marginBottom: '12px',
		},
		ownerLabel: {
			height: '36px',
		},
		nameContainer: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'flex-end',
			position: 'relative',
			marginTop: '5px'
		},
		input: {
			'& input': {
				marginTop: '16px',
				width: '100%',
				color: '#000000',
				lineHeight: '24px',
				wordBreak: 'break-all',
				letterSpacing: '0em',
				fontFamily: cs.FONT.family,
				fontSize: cs.FONT.size.sm,
				fontWeight: cs.FONT.weight.regular,
				outline: 'none',
				border: 'none',
			},
		},
		sharingTitle: {
			color: '#606060',
			marginLeft: '3px',
		},
		icon: {
			fontSize: '15px',
			marginRight: '12px',
		},
		explanation: {
			color: '#606060',
			marginLeft: '32px',
		},
		grayRect: {
			width: '36px',
			height: '36px',
			backgroundColor: '#F2F2F2',
			border: '1px solid #D8D8D8',
			borderRadius: '3px',
			marginRight: '12px',
		},
		description: {
			'& .MuiInput-input': {
				width: '100%',
				height: '180px !important',
				borderRadius: '3px',
				resize: 'none',
				border: '1px solid #D8D8D8',
				color: '#2C2C2C',
				fontFamily: 'Montserrat',
				fontSize: '12px',
				fontWeight: 600,
				padding: '3px'
			},
			'& .MuiInputBase-input:focus': {
				outline: '-webkit-focus-ring-color auto 1px',
			}
		},
		showMoreButton: {
			color: '#606060',
			border: '1px solid #D8D8D8',
			borderRadius: '3px',
			fontSize: '12px',
			marginBottom: '0px',
			zIndex: 1,
			height: '26px',
			minWidth: '16px',
			'& span': {
				marginTop: '1px',
			},
			'&:hover': {
				border: '1px solid #3A84FF;',
			},
		},
		deliveredCheckbox: {
			padding: '0px',
			marginRight: '12px',
			'& svg': {
				fontSize: '1rem',
			},
		},
		deliveredText: {
			fontSize: 12,
			color: '#2C2C2C',
			opacity: 1,
			fontWeight: 'normal',
		},
		datePicker: {
			'& input': {
				width: '90px',
			},
		}
	})
);

function getProjectTypeOptions(isAdmin: boolean, classes: ClassNameMap<string>) {
	var options: any = {
		internal: {
			header: 'Internal',
			value: 'internal',
			icon:
				<Typography variant="h6" className={classes.flex}>
					<InternalIcon className={classes.icon} />
					Internal
    		</Typography>
			,
			description: 'Collaborate within your company. Use this setting when sharing information with your colleagues.'
		},
		request: {
			header: 'Request',
			value: 'issue',
			icon:
				<Typography variant="h6" className={classes.flex}>
					<RequestIcon className={classes.icon} />
					Request
				</Typography>
			,
			description: 'Issue a request for information from an external company. Use this setting when the other company is responsible for providing most of the information in the task list.'
		}
	};
	if (isAdmin) {
		options.share = {
			header: 'Share',
			value: 'share',
			icon:
				<Typography variant="h6" className={classes.flex}>
					<ShareIcon className={classes.icon} />
					Share
				</Typography>
			,
			description: 'Send information to an external company. Use this setting when your company has the primary responsibility for providing the information in the task list.'
		};
	}
	return options;
}

interface IProjectDetailStep {
	submitTitle: string;
	isAdmin: boolean;
	newTemplate: IProject;
	setNewTemplate: React.Dispatch<React.SetStateAction<IProject>>;
	externalCompanyId: string;
	selectedTemplate: any;
	projectOptionsSelected: IProjectOptionsSelected;
	setProjectOptionsSelected: React.Dispatch<React.SetStateAction<IProjectOptionsSelected>>;
	updateListRecon(field: string, value: string): void;
	reconSettingsClicked(): void;
	inviteCompany: INewCompanyType;
	setInviteCompany: React.Dispatch<React.SetStateAction<INewCompanyType>>;
	additionalInternalOwners: any[];
	additionalExternalOwners: any[];
	setAdditionalInternalOwners: React.Dispatch<React.SetStateAction<any[]>>;
	setAdditionalExternalOwners: React.Dispatch<React.SetStateAction<any[]>>;
}

export default function ProjectDetailStep(props: IProjectDetailStep) {

	const { submitTitle, isAdmin, newTemplate, setNewTemplate, externalCompanyId, selectedTemplate,
		projectOptionsSelected, setProjectOptionsSelected, updateListRecon, reconSettingsClicked, inviteCompany, setInviteCompany,
		additionalInternalOwners, additionalExternalOwners, setAdditionalInternalOwners, setAdditionalExternalOwners } = props;

	const classes = useStyles();
	const globalStyles = useGlobalStyles();
  const state = useStateContext();
	const adminPlus = state.currentUser.isAdmin;


	const projectTypes = getProjectTypeOptions(adminPlus, classes);

	const [customRecurringProjectsModal, setCustomRecurringProjectsModal] = useState<boolean>(false);
	const [policies, setPolicies] = useState([]);

	/* show modal if 'Custom' is selected from the recurring projects dropdown */
	useEffect(() => {
		if (newTemplate.scheduleType === '6') setCustomRecurringProjectsModal(true);
	}, [newTemplate.scheduleType]);

	// As we only use retention policies on this page, makes sense to load them
	// in here //////////////////  Extract to own
	///////////////////////////////////////// component
	const {
		loading: policyLoading,
		error: policyError,
		data: policyData,
	} = useRetentionPolicies({ companyId: state.currentUser.lastViewedCompanyId });

	useEffect(() => {
		if (!policyData) return;

		var pols = (policyData as any).retentionPolicies;
		if (!pols) return;

		setPolicies(pols);
		var defaultPolicy = pols.find((rp: any) => rp.isDefault);
		if (defaultPolicy) {
			setNewTemplate({ ...newTemplate, retentionPolicyId: defaultPolicy.id });
		}
	}, [policyData]);
	/////////////////////////////////////////////////////////////////////////////

	const owners = newTemplate.userCompanyOwners;
	const setOwners = (o: React.SetStateAction<(| SearchCompanyUsers_searchCompanyUsers_users | SearchCompanyUsers_searchCompanyUsers_teams)[]>) => {
		setNewTemplate({ ...newTemplate, userCompanyOwners: o });
	};

	// function handleChangeName(e: any) {
	// 	setNewTemplate({ ...newTemplate, name: e.target.value });
	// };

	// function handleChangeDescription(event: React.ChangeEvent<HTMLTextAreaElement>) {
	// 	setNewTemplate({
	// 		...newTemplate,
	// 		description: event.target.value,
	// 	});
	// };

	const handleBlur = (event: any) => {
		const { name, value } = event.target;
		if (name && value) setNewTemplate({ ...newTemplate, [name]: value });
	}

	const onEnterClicked = (event: any) => {
		if (event && event.target) event.target.blur();
	}

	function handleChangeSharing(_: React.ChangeEvent<HTMLInputElement>, value: string) {
		setProjectOptionsSelected({ ...projectOptionsSelected, projectType: value })
	}

	const handleChangeEndDate = (date: Date | null) => {
		if (date) {
			setNewTemplate({ ...newTemplate, estimatedEndDate: date });
		}
	};

	const handleChangeStartDate = (date: Date | null) => {
		if (date) {
			setNewTemplate({ ...newTemplate, estimatedStartDate: date });
		}
	};

	const handlePolicyUpdate = (option: any) => {
		setNewTemplate({ ...newTemplate, retentionPolicyId: option });
	};

	const handleRemoveOwnerClick = (removedOwner: any) => {
		if (owners.length <= 1) return; // a list must have at least 1 owner

		// if removed Owner shares id && typename, take it out
		var newOwners = owners.filter((owner: any) => {
			if (owner.__typename === removedOwner.__typename) {
				return owner.email !== removedOwner.email;
			} else {
				return true;
			}
		});

		setOwners(newOwners);
	};

	const handleCalendarUpdate = (e: any) => {
		setNewTemplate({ ...newTemplate, scheduleType: e.target.value as number });
	};

	const updateCustomRecurringProject = (p: any) => {
		setNewTemplate({ ...newTemplate, customSchedule: p });
		setCustomRecurringProjectsModal(false);
	};

	const showWarning = false;

	const handleReconChange = (v: any) => {
		updateListRecon('type', v);
	};

	const onFileSharingClicked = () => {
		setNewTemplate({ ...newTemplate, deliverFileOnUpload: newTemplate.deliverFileOnUpload === 'yes' ? 'not_set' : 'yes' });
	}

	const updateLabelsChanged = (labels: any[]) => {
		setNewTemplate({ ...newTemplate, labels });
	}

	return (
		<div className={classes.root}>
			{customRecurringProjectsModal && (
				<CustomRecurringProjects onAccept={updateCustomRecurringProject} onClose={() => setCustomRecurringProjectsModal(false)} />
			)}
			<div className={classes.body}>
				<div className={classes.pane}>
					<Typography variant="h2">{submitTitle}</Typography>
					<FormControl component="fieldset" style={{ marginTop: '24px' }}>
						<Typography variant="h6" className={classes.sharingTitle}>
							Sharing
						</Typography>
						<RadioGroup aria-label="sharing" name="sharing" value={projectOptionsSelected.projectType} onChange={handleChangeSharing}>
							{
								Object.keys(projectTypes).map((projectType: string) => (
									<React.Fragment key={projectType}>
										<FormControlLabel data-cy={`${projectTypes[projectType].value}-radio`} value={projectTypes[projectType].value} label={projectTypes[projectType].icon} control={<Radio color="primary" />} />
										<Typography variant="h6" className={classes.explanation}>
											{projectTypes[projectType].description}
										</Typography>
									</React.Fragment>
								))
							}
						</RadioGroup>
					</FormControl>
					{projectOptionsSelected.projectType !== projectTypes.internal.value && (
						<div className={classes.warning}>
							<WarningIcon />
							<Typography variant="h6" style={{ marginLeft: '12px' }}>
								This list and all of its contents will be public to the selected
								company upon creation.
							</Typography>
						</div>
					)}
				</div>
				<div data-cy="project-name-container" className={classes.pane}>
					<Typography variant="h2">Project Details</Typography>
					{/* <FormControl style={{ marginTop: 5, width: '100%' }}>
						<Input value={newTemplate.name} onChange={handleChangeName} placeholder='Enter Project Name' />
					</FormControl> */}

					<div className={clsx(globalStyles.showIconOnHover, classes.nameContainer)}>
						<EditableInput className={classes.input} initialValue={newTemplate.name} name='name' placeholder='Enter Project Name' hideIcon
							InputProps={{ disableUnderline: false }} dataCy='name-input'
							onBlur={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleBlur(event)} onEnterClicked={(event: any) => onEnterClicked(event)} />
						<UpdateIcon className={classes.pencil} style={{ position: 'absolute', 'right': 0 }} />
					</div>


					{/* <div>
						<Typography variant="h6" className={classes.secondary}>
							Template
					</Typography>
						<div className={classes.flex}>
							<div className={classes.grayRect} />
							<Typography variant="h6">{selectedTemplate.name}</Typography>
						</div>
					</div> */}

					<div>
						<Typography variant="h6" className={classes.secondary}>
							Internal Project owners
						</Typography>
						<div className={classes.flex}>
							<RenderOwnersRow style={{ height: '36px' }} owners={owners} handleRemoveOwnerClick={handleRemoveOwnerClick} />
							<OwnerForm owners={owners} adminPlus={adminPlus} setOwners={setOwners}
								additionalNewOwners={additionalInternalOwners} setAdditionalNewOwners={setAdditionalInternalOwners} />
						</div>
					</div>
					{showWarning && (projectOptionsSelected.projectType === projectTypes.share.value || projectOptionsSelected.projectType === projectTypes.request.value) && (
						<Typography variant="h6" style={{ color: 'red', fontWeight: 500, margin: 0 }} >
							*You must issue this project to a company if you have selected
							Share or Request.
						</Typography>
					)}
					{projectOptionsSelected.projectType !== projectTypes.internal.value && (
						<div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row' }}>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div style={{ marginTop: 7 }}>
									<Typography style={{ color: '#606060', marginBottom: 12 }} variant="h6">
										External Company*
									</Typography>
								</div>
								<ExternalCompany sharing={projectOptionsSelected.projectType} newTemplate={newTemplate} setNewTemplate={setNewTemplate}
									inviteCompany={inviteCompany} setInviteCompany={setInviteCompany} showWarning={showWarning}
									additionalExternalOwners={additionalExternalOwners} setAdditionalExternalOwners={setAdditionalExternalOwners} />
							</div>
							{idx(newTemplate, n => n.otherCompanyOwners.length > 0) && (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<div style={{ marginTop: 7 }}>
										<Typography style={{ color: '#606060', marginBottom: 12 }} variant="h6">
											External Project Owners*
										</Typography>
									</div>
									<ExternalUsers newTemplate={newTemplate} setNewTemplate={setNewTemplate} externalCompanyId={externalCompanyId} isAdmin={true}
										additionalExternalOwners={additionalExternalOwners} setAdditionalExternalOwners={setAdditionalExternalOwners} />
								</div>
							)}
						</div>
					)}

					<div className="flex items-center">
              			<div className='mr-2 MuiTypography-h6'>Labels</div>
						<ListLabelsContainer
							selectedLabels={newTemplate?.labels || []}
							labelsChanged={updateLabelsChanged}
						/>
					</div>

					{(Boolean(idx(projectTypes, type => type.share.value)) && projectOptionsSelected.projectType === projectTypes.share.value) && (
						<div style={{ display: 'flex', marginTop: 7, flexDirection: 'column' }}>
							<Typography variant="h6" className={classes.secondaryText}>
								File Sharing*
							</Typography>
							<div style={{ display: 'flex' }}>
								<Checkbox className={classes.deliveredCheckbox} color='primary' checked={newTemplate.deliverFileOnUpload === 'yes'} onClick={onFileSharingClicked} data-cy='file-sharing'></Checkbox>
								<div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
									<Typography className={classes.deliveredText}>Automatically set task status to <b>Delivered</b> upon file upload</Typography>
								</div>
							</div>
						</div>
					)}
					<div style={{ display: 'flex', flexDirection: 'row', marginTop: 7 }}>
						<div style={{ display: 'flex', flexDirection: 'column' }} >
							<Typography variant="h6" className={classes.secondaryText}>
								Project Start Date
							</Typography>
							<DatePicker
								value={newTemplate.estimatedStartDate ? newTemplate.estimatedStartDate : null}
								onChange={handleChangeStartDate}
								placeholder="Add Start date"
								classes={classes.datePicker}
							/>
						</div>
						<div style={{ display: 'flex', flexDirection: 'column', margin: '0px 20px' }} >
							<Typography variant="h6" className={classes.secondaryText}>
								Project End Date
							</Typography>
							<DatePicker
								value={newTemplate.estimatedEndDate ? newTemplate.estimatedEndDate : null}
								onChange={handleChangeEndDate}
								placeholder="Add End date"
								classes={classes.datePicker}
							/>
						</div>
						<div style={{ width: '40%', display: 'flex', alignItems: 'flex-end' }}>
							<RepeatingProjectDropdown data-cy="repeating-dropdown" onChange={handleCalendarUpdate} value={newTemplate.scheduleType} />
						</div>
					</div>

					<div style={{ display: 'flex', marginTop: 7, alignItems: 'flex-end' }}>
						<div style={{ width: '40%', marginRight: 20 }}>
							<Typography variant="h6" className={classes.secondaryText}>
								Reconciliations
							</Typography>

							<Dropdown handleUpdate={handleReconChange} options={RECON_TYPES} placeholder="Not Active" selected={newTemplate.reconciliations || '0'} small />
						</div>
						<div>
							<Button onClick={reconSettingsClicked}>
								Reconciliation Settings
							</Button>
						</div>
					</div>

					<div style={{ display: 'flex', marginTop: 7, alignItems: 'center' }}>
						<div style={{ width: '40%', marginRight: 20 }}>
							<Typography variant="h6" className={classes.secondaryText}>
								Retention Policy
							</Typography>
							<Dropdown options={policies} placeholder="Select a Policy" handleUpdate={handlePolicyUpdate} selected={newTemplate.retentionPolicyId as string} small />
						</div>
					</div>
					{/* <div>
						<Typography variant="h6" className={classes.secondary}>
							Description
						</Typography>
						<textarea data-cy="new-project-desc" className={classes.description} value={newTemplate.description} onChange={handleChangeDescription} />
					</div> */}
					<div>
						<Typography variant="h6" className={classes.secondary}>
							Description
						</Typography>
						<EditableInput initialValue={newTemplate.description} name='description' type='text' multiline className={classes.description} hideIcon dataCy='new-project-desc'
							onBlur={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleBlur(event)} onEnterClicked={(event: any) => onEnterClicked(event)} />
					</div>
				</div>
			</div>
		</div>
	)
}

// function Input(props: any) {
// 	const [hover, setHover] = useState<boolean>(false);
// 	const classes = useStyles();

// 	return (
// 		<div onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)} style={{ display: 'flex', borderBottom: '1px solid #D8D8D8', alignItems: 'flex-end', }}>
// 			<input data-cy="name-input" className={classes.input} value={props.value} onChange={props.onChange} placeholder={props.placeholder} />
// 			{
// 				<UpdateIcon
// 					className={clsx(hover ? null : classes.none, classes.pencil)}
// 				/>
// 			}
// 		</div>
// 	);
// }
